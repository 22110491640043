<template>
	<div
		class="outer-page-W"
		:id="'page-' + i"
		ref=""
		v-for="(outer, i) in indImg"
		:key="i"
	>
		<template v-if="loaded">
			<div
				:data-question="question.examNumber"
				class="pdf-screen-W"
				v-for="(page, j) in outer"
				:key="j"
			>
				<div class="pdf-screen-C">
					<r-flex class="pdf-screen-question-C" v-if="j === 0">
						<span class="original-question">
							Orijinal Soru: {{ qtype }} {{ question.examNumber }}
						</span>
						<span class="question-osym">{{ pdftelif }}</span>
						<r-flex class="r-flx-nop question-content">
							<r-flex flx="1" class="r-flx-nop" style="width: 3%; flex: 0 3%">
								<strong>{{ question.examNumber }}.</strong>
							</r-flex>
							<r-flex flx="11" class="r-flx-nop">
								<r-flex
									v-if="question.isCase"
									v-html="question.case"
									class="r-flx-nop case-data"
								></r-flex>
								<r-flex
									v-html="question.detail"
									class="r-flx-nop question-data"
								></r-flex>
								<!-- <r-flex>
									<r-flex
										class="r-flx-nop"
										v-for="(choice, i) in question.choiceList"
										:key="i"
									>
										<strong>{{ store.state.choices[i] }})&nbsp;</strong>
										<div v-html="choice"></div>
									</r-flex>
								</r-flex> -->
							</r-flex>
							<!-- <r-flex>
								<strong>Doğru Cevap:</strong>
								{{ question.answer }}
							</r-flex> -->
						</r-flex>
					</r-flex>
					<r-flex class="pdf-view-content-C r-flx-nop">
						<r-flex class="pdf-right-color" flx="3"></r-flex>
						<r-flex
							align="right"
							class="pdf-content-desc r-flx-nop"
							v-if="j === 0"
						>
							<!--
							<r-flex flx="9" align="center">
								<h3 v-if="inData[i]" class="title-comment">
									{{ inData[i].pagetitle }}
								</h3>
								<span v-if="inData[i]" class="question-osym">
									{{ inData[i].pagedesc }}
								</span>
							</r-flex>
							-->
							<!-- <r-flex flx="3" class="pdf-notes-C">
								<r-flex align="center">
									<strong>İLGİLİ NOTLAR</strong>
								</r-flex>
							</r-flex> -->
						</r-flex>
						<r-flex class="pdf-view-images-C r-flx-nop">
							<r-flex
								v-for="(pic, k) in page"
								:key="k"
								:class="['r-flx-nop pdf-img-line', 'pdf-img-line-disp-' + rnd]"
								:id="
									'imgc-' +
									question.examNumber +
									'-' +
									pic.iid +
									'-' +
									pic.tid +
									'-' +
									pic.pid +
									'-' +
									pic.kid
								"
							>
								<r-flex
									flx="9"
									align="center flex-start"
									class="pdf-view-image r-ph-8"
								>
									<img
										:style="{
											height: heightarr['c' + pic.id]
												? heightarr['c' + pic.id] + 'px'
												: '',
										}"
										class="pdf-img-n-disp"
										:src="pic.url"
										:id="
											'img-' +
											pic.iid +
											'-' +
											pic.tid +
											'-' +
											pic.pid +
											'-' +
											pic.kid
										"
										@click="selectedImg(pic.iid, pic.tid, pic.pid, pic.kid)"
									/>
								</r-flex>
								<r-flex flx="3" class="pdf-notes-C">
									<r-flex align="center">
										<r-btn
											v-if="
												store.state.supers.indexOf(store.state.userrole) >= 0 ||
												pic.userId === store.state.userid
											"
											@click="AddNoteM(pic)"
											style="width: 100%"
										>
											{{ pic.note === '' ? 'Not Ekle' : 'Notu Düzenle' }}
										</r-btn>
										<div v-if="pic.note !== ''" v-html="pic.note"></div>
									</r-flex>
								</r-flex>
							</r-flex>
						</r-flex>
					</r-flex>
					<r-flex align="center" class="pdf-footer">
						{{ pdffooter }}
					</r-flex>
				</div>
			</div>
		</template>
	</div>
	<div
		class="outer-page-W-temp pdf-screen-W"
		v-for="(page, i) in inImg"
		:key="i"
	>
		<div class="pdf-screen-C-temp" v-if="loaded">
			<r-flex :class="['pdf-screen-question-C-temp' + rnd]">
				<span class="original-question">
					Orijinal Soru: {{ qtype }} {{ question.examNumber }}
				</span>
				<span class="question-osym">{{ pdftelif }}</span>
				<r-flex class="r-flx-nop question-content">
					<r-flex flx="1" class="r-flx-nop" style="width: 3%; flex: 0 1 3%">
						<strong>{{ question.examNumber }}.</strong>
					</r-flex>
					<r-flex flx="11" class="r-flx-nop">
						<r-flex
							v-if="question.isCase"
							v-html="question.case"
							class="r-flx-nop case-data"
						></r-flex>
						<r-flex
							v-html="question.detail"
							class="r-flx-nop question-data"
						></r-flex>
						<r-flex>
							<r-flex
								class="r-flx-nop"
								v-for="(choice, i) in question.choiceList"
								:key="i"
							>
								<strong>{{ store.state.choices[i] }})&nbsp;</strong>
								<div v-html="choice"></div>
							</r-flex>
						</r-flex>
					</r-flex>
					<r-flex>
						<strong>Doğru Cevap:</strong>
						{{ question.answer }}
					</r-flex>
				</r-flex>
			</r-flex>
			<r-flex class="pdf-view-content-C r-flx-nop">
				<r-flex align="center" class="pdf-content-desc-temp r-flx-nop">
					<r-flex flx="9" align="center">
						<h3 v-if="inData[i]" class="title-comment">
							{{ inData[i].pagetitle }}
						</h3>
						<span v-if="inData[i]" class="question-osym">
							{{ inData[i].pagedesc }}
						</span>
					</r-flex>
					<r-flex flx="3" class="pdf-notes-C">
						<r-flex align="center">
							<strong>İLGİLİ NOTLAR</strong>
						</r-flex>
					</r-flex>
				</r-flex>
				<r-flex class="pdf-view-images-C r-flx-nop">
					<r-flex
						v-for="(pic, j) in inImg[i]"
						:key="j"
						:class="['r-flx-nop pdf-img-line-temp', 'pdf-line-select-' + rnd]"
						:id="
							'imgc-' +
							question.examNumber +
							'-' +
							pic.iid +
							'-' +
							pic.tid +
							'-' +
							pic.pid +
							'-' +
							pic.kid
						"
					>
						<r-flex
							flx="9"
							align="center flex-start"
							class="pdf-view-image r-ph-8"
						>
							<img
								:style="{
									height: heightarr['c' + pic.id]
										? heightarr['c' + pic.id] + 'px'
										: '',
								}"
								class="referans-img-tmp"
								:data-imgquestion="question.examNumber"
								:src="pic.url"
								:id="'img-' + i + '-' + j"
							/>
						</r-flex>
						<r-flex flx="3" class="pdf-notes-C">
							<r-flex align="center">
								<div v-if="pic.note !== ''" v-html="pic.note"></div>
							</r-flex>
						</r-flex>
					</r-flex>
				</r-flex>
			</r-flex>
			<r-flex align="center" class="pdf-footer-temp">
				{{ pdffooter }}
			</r-flex>
		</div>
	</div>

	<div
		class="outer-page-W outer-page-W-save"
		:id="'page-' + i"
		ref=""
		v-for="(outer, i) in indImg"
		:key="i"
	>
		<template v-if="loaded">
			<div
				:class="[
					'pdf-screen-W-save',
					'sep-page-save-' + question.examNumber + '-' + i,
				]"
				:data-question="question.examNumber"
				v-for="(page, j) in outer"
				:key="j"
			>
				<div class="pdf-screen-C-save">
					<r-flex class="pdf-screen-question-C-save" v-if="j === 0">
						<span class="original-question">
							Orijinal Soru: {{ qtype }} {{ question.examNumber }}
						</span>
						<span class="question-osym">{{ pdftelif }}</span>
						<r-flex class="r-flx-nop question-content">
							<r-flex flx="1" class="r-flx-nop" style="width: 3%; flex: 0 1 3%">
								<strong>{{ question.examNumber }}.</strong>
							</r-flex>
							<r-flex flx="11" class="r-flx-nop">
								<r-flex
									v-if="question.isCase"
									v-html="question.case"
									class="r-flx-nop case-data"
								></r-flex>
								<r-flex
									v-html="question.detail"
									class="r-flx-nop question-data"
								></r-flex>
								<!-- <r-flex>
									<r-flex
										class="r-flx-nop"
										v-for="(choice, i) in question.choiceList"
										:key="i"
									>
										<strong>{{ store.state.choices[i] }})&nbsp;</strong>
										<div v-html="choice"></div>
									</r-flex>
								</r-flex> -->
							</r-flex>
							<!-- <r-flex>
								<strong>Doğru Cevap:</strong>
								{{ question.answer }}
							</r-flex> -->
						</r-flex>
					</r-flex>
					<r-flex class="pdf-view-content-C r-flx-nop">
						<!-- <r-flex class="pdf-right-color" flx="3"></r-flex>
						<r-flex
							align="center"
							class="pdf-content-desc-save r-flx-nop"
							v-if="j === 0"
						>
						
							<r-flex flx="9" align="center">
								<h3 v-if="inData[i]" class="title-comment">
									{{ inData[i].pagetitle }}
								</h3>
								<span v-if="inData[i]" class="question-osym">
									{{ inData[i].pagedesc }}
								</span>
							</r-flex>
							
							<r-flex flx="3" class="pdf-notes-C">
								<r-flex align="center">
									<strong>İLGİLİ NOTLAR</strong>
								</r-flex>
							</r-flex>
						</r-flex> -->
						<r-flex class="pdf-view-images-C r-flx-nop">
							<r-flex
								v-for="(pic, j) in page"
								:key="j"
								class="r-flx-nop pdf-img-line-save"
								:id="
									'imgc-' +
									pic.iid +
									'-' +
									pic.tid +
									'-' +
									pic.pid +
									'-' +
									pic.kid
								"
							>
								<r-flex
									flx="11"
									align="center flex-start"
									class="pdf-view-image r-ph-8"
								>
									<img
										:style="{
											height: heightarr['c' + pic.id]
												? heightarr['c' + pic.id] + 'px'
												: '',
										}"
										class="pdf-img-n"
										:src="pic.url"
										:id="
											'img-' +
											pic.iid +
											'-' +
											pic.tid +
											'-' +
											pic.pid +
											'-' +
											pic.kid
										"
										@click="selectedImg(pic.iid, pic.tid, pic.pid, pic.kid)"
									/>
								</r-flex>
								<!-- <r-flex flx="3" class="pdf-notes-C">
									<r-flex align="center">
										<div v-if="pic.note !== ''" v-html="pic.note"></div>
									</r-flex>
								</r-flex> -->
							</r-flex>
						</r-flex>
					</r-flex>
					<r-flex align="center" class="pdf-footer-save">
						{{ pdffooter }}
					</r-flex>
				</div>
			</div>
		</template>
	</div>

	<r-modal
		:title="noteTitle"
		v-if="noteModal"
		v-model:isactive="noteModal"
		:closeout="false"
		minWidth="750px"
	>
		<vue-editor
			height="400px"
			:editorToolbar="
				store.state.supers.indexOf(store.state.userrole) >= 0
					? adminToolBar
					: noteToolBar
			"
			v-model="noteHtml"
			:htmlBtn="
				store.state.supers.indexOf(store.state.userrole) >= 0 ? true : false
			"
		/>
		<template v-slot:otherbuttons>
			<r-btn bgcolor="#423aeb" @click="OpenHazirKalip" size="medium">
				Hazır Kalıp Kullan
			</r-btn>
			<r-btn bgcolor="#15880d" @click="AddNote" size="medium">
				Notu Kaydet
			</r-btn>
			<r-btn bgcolor="#bb0202" @click="DeleteNote" size="medium">
				Notu Sil
			</r-btn>
		</template>
	</r-modal>
	<r-panel
		title="Hazır Kalıp Metinleri"
		v-if="hazirKaliplarPanel"
		v-model:isactive="hazirKaliplarPanel"
		pos="center"
	>
		<r-data-list
			:headdata="headHazirKalip"
			:listdata="hazirKaliplarList"
			:searchfilter="searchHazirKalip"
			v-model:qu="qHazirKalip"
			:opt="optionsHazirKaliplar"
		>
			<template v-slot:rowextrabtn="{ datarow }">
				<r-btn @click="AddHazirKalip(datarow)" bgcolor="#003642">Seç</r-btn>
			</template>
		</r-data-list>
	</r-panel>
</template>
<script>
	import { onMounted, ref, watch, computed } from 'vue'
	import { useStore } from 'vuex'
	import { trToUpper } from '@/mix/global'
	export default {
		props: {
			pagedata: Array,
			imagelist: Array,
			qtype: String,
			question: Object,
			newdata: Array,
			updated: {
				type: Boolean,
				default: false,
			},
			imagesloaded: {
				type: Boolean,
				default: false,
			},
			errorimages: {
				type: Array,
				default: new Array(),
			},
		},
		emits: [
			'savenote',
			'selectimage',
			'update:updated',
			'update:imagesloaded',
			'update:errorimages',
		],
		setup(props, { emit }) {
			const store = useStore()
			const contentH = ref(null)
			const contentOtherH = ref(null)
			const inData = ref(props.pagedata)
			const inImg = ref(props.imagelist)
			const indImg = ref([])
			const loaded = ref(false)
			const imagesLoaded = ref(false)
			const noteTitle = ref('Referans Notu')
			const noteModal = ref(false)
			const noteData = ref('')
			const noteHtml = ref('')
			const pdffooter = ref('')
			const pdftelif = ref('')
			const heightarr = ref({})
			const rnd = ref(parseInt(Math.random() * 1000000000))
			const pdffactor = ref(1600 / 700)
			const inUpdate = ref(props.updated)
			const hazirKaliplarPanel = ref(false)
			const hazirKaliplarList = ref([])
			const counter = ref(0)
			const imgcount = ref(null)
			const qHazirKalip = ref('')
			const noteToolBar = ref({
				container: [
					['bold', 'italic', 'underline'],
					[
						{ align: '' },
						{ align: 'center' },
						{ align: 'right' },
						{ align: 'justify' },
					],
					[{ color: [] }, { background: [] }],
					[{ script: 'sub' }, { script: 'super' }]['clean'],
				],
			})
			const adminToolBar = ref({
				container: [
					[{ header: [false, 1, 2, 3, 4, 5, 6] }],
					['bold', 'italic', 'underline'],
					[
						{ align: '' },
						{ align: 'center' },
						{ align: 'right' },
						{ align: 'justify' },
					],
					[{ color: [] }, { background: [] }],
					[{ script: 'sub' }, { script: 'super' }]['clean'],
				],
			})
			const headHazirKalip = computed(() => {
				return [
					{
						title: 'Kalıp',
						style: { width: '90%' },
						key: 'detail',
						html: true,
					},
				]
			})
			const optionsHazirKaliplar = ref({
				count: true,
				buttons: {
					style: { width: '10%' },
				},
			})
			const countup = computed(() => {
				if (counter.value >= imgcount.value) {
					imagesLoaded.value = true
					refreshData()
				}
			})
			const searchHazirKalip = v => {
				return (
					v.detail && trToUpper(v.detail).includes(trToUpper(qHazirKalip.value))
				)
			}
			const referenceImagesLoaded = () => {
				var imgtotal = 0
				var imgs = document.querySelectorAll('img')
				imgcount.value = imgs.length
				if (imgcount.value > 0) {
					imgs.forEach(img => {
						if (img.complete || img.height > 0) {
							counter.value++
							countup.value
						} else {
							img.addEventListener('load', function () {
								counter.value++
								countup.value
							})
						}
					})

					var imagesLoadInterval = setInterval(() => {
						imgtotal++
						if (imgtotal > imgcount.value) clearInterval(imagesLoadInterval)
						if (counter.value >= imgcount.value - 1) {
							clearInterval(imagesLoadInterval)
							imagesLoaded.value = true
						} else {
							imagesLoaded.value = false
						}
						emit('update:imagesloaded', imagesLoaded.value)
					}, 300)
				} else {
					imagesLoaded.value = true
					refreshData()
				}
			}
			const refreshData = () => {
				setTimeout(() => {
					inUpdate.value = false
					indImg.value = []
					heightarr.value = {}
					let wrapperH = document.getElementsByClassName('outer-page-W-temp')[0]
					let questionh = document.getElementsByClassName(
						'pdf-screen-question-C-temp' + rnd.value
					)[0]
					let commenth = document.getElementsByClassName(
						'pdf-content-desc-temp'
					)[0]
					let footerh = document.getElementsByClassName('pdf-footer-temp')[0]

					let wrpstyle =
						wrapperH.currentStyle || window.getComputedStyle(wrapperH)
					let wrps = wrpstyle.paddingTop || wrpstyle['padding-top']
					contentH.value =
						(wrapperH.clientHeight || 0) -
						parseInt(wrps) * 2 -
						(questionh.clientHeight || 0) -
						(commenth.clientHeight || 0) -
						(footerh.clientHeight || 0)
					contentOtherH.value =
						(wrapperH.clientHeight || 0) -
						parseInt(wrps) * 2 -
						(footerh.clientHeight || 0)

					var imgarr = document.querySelectorAll('.pdf-line-select-' + rnd.value)
					var count = 0

					inImg.value.forEach((arr, i) => {
						var temph = 0
						var newpage = 0
						indImg.value[i] = []
						if (arr.length > 0 && imgarr.length > 0) {
							arr.forEach(img => {
								if (
									imgarr[count].clientHeight > contentH.value &&
									newpage === 0
								) {
									let addpage = false
									if (temph > 0) {
										newpage++
										temph = imgarr[count].clientHeight
										addpage = true
									}
									if (newpage === 0)
										heightarr.value['c' + img.id] = contentH.value - 24
									if (!indImg.value[i][newpage]) indImg.value[i][newpage] = []
									indImg.value[i][newpage].push(img)
									count++
									if (!addpage) newpage++
								} else {
									temph = temph + imgarr[count].clientHeight
									if (newpage === 0) {
										if (temph > contentH.value) {
											temph = imgarr[count].clientHeight
											newpage++
										}
									} else {
										if (temph > contentOtherH.value) {
											temph = imgarr[count].clientHeight
											newpage++
										}
									}
									if (!indImg.value[i][newpage]) indImg.value[i][newpage] = []
									indImg.value[i][newpage].push(img)
									count++
								}
							})
						} else {
							indImg.value[i] = [[]]
						}
					})
					indImg.value.splice(0, indImg.value.length, ...indImg.value)
					inUpdate.value = true
					emit('update:updated', true)
				}, 100)
			}
			const AddNoteM = d => {
				noteData.value = d
				noteHtml.value = d.note
				noteModal.value = true
			}
			const AddNote = () => {
				emit('savenote', noteData.value, noteHtml.value)
				noteModal.value = false
			}
			const DeleteNote = () => {
				noteHtml.value = ''
				emit('savenote', noteData.value, noteHtml.value)
				noteModal.value = false
			}
			const selectedImg = (i, j, k, l) => {
				emit('selectimage', i, j, k, l)
			}
			const getSettings = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'settings/getsettingsforuser',
				})
				return list
			}
			const openSettings = async () => {
				let data = await getSettings()
				data.data.forEach(o => {
					if (o.key === 'pdffooter') pdffooter.value = o.value
					if (o.key === 'pdftelif') pdftelif.value = o.value
				})
			}
			const GetHazirKaliplar = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'presets/getallpresets',
					params: {
						page: 1,
						size: 500,
						order: 0,
					},
				})

				list.data = list.data.sort((a, b) => {
					return a.priority - b.priority
				})
				return list.data
			}
			const OpenHazirKalip = async () => {
				hazirKaliplarList.value = await GetHazirKaliplar()
				hazirKaliplarPanel.value = true
			}
			const AddHazirKalip = d => {
				noteHtml.value += d.detail
				hazirKaliplarPanel.value = false
			}
			onMounted(() => {
				loaded.value = true
				openSettings()
				setTimeout(() => {
					referenceImagesLoaded()
				}, 100)
			})
			watch(
				() => [props.imagelist],
				() => {
					inImg.value.splice(0, inImg.value.length, ...props.imagelist)
					setTimeout(() => {
						referenceImagesLoaded()
					}, 100)
				}
			)

			return {
				store,
				inData,
				inImg,
				indImg,
				loaded,
				AddNoteM,
				AddNote,
				noteTitle,
				noteModal,
				noteData,
				noteToolBar,
				adminToolBar,
				noteHtml,
				selectedImg,
				rnd,
				DeleteNote,
				pdffooter,
				pdftelif,
				heightarr,
				pdffactor,
				OpenHazirKalip,
				AddHazirKalip,
				hazirKaliplarPanel,
				hazirKaliplarList,
				headHazirKalip,
				searchHazirKalip,
				qHazirKalip,
				optionsHazirKaliplar,
			}
		},
	}
</script>
<style>
	.outer-page-W-temp {
		opacity: 0;
		z-index: 0;
		position: fixed;
		left: 100%;
	}
	.r-referans-image-panel-C {
		display: flex;
		background-color: #ececec;
	}
	.r-referans-image-panel-C .r-panel-content-C {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.r-referans-soru-C {
		border: 1px solid #ececec;
		background-color: #f5f5f5;
		border-radius: 3px;
		padding: 12px;
	}
	.pdf-screen-W {
		display: flex;
		width: 700px;
		height: 991px;
		padding: 14px;
		flex-direction: column;
		background-color: #fff;
	}
	.pdf-screen-C,
	.pdf-screen-C-temp,
	.pdf-screen-C-save {
		display: flex;
		border: 2px solid #c00002;
		width: 100%;
		height: 100%;
		flex-direction: column;
		position: relative;
	}
	.pdf-screen-question-C,
	.pdf-screen-question-C-temp,
	.pdf-screen-question-C-save {
		background-color: #fce9da;
		padding: 1%;
		width: 100%;
		flex: 0 1 auto;
	}
	.question-content {
		font-size: 10px;
	}
	.pdf-screen-question-C .original-question,
	.pdf-screen-question-C-temp .original-question,
	.pdf-screen-question-C-save .original-question {
		font-size: 20px;
		font-weight: 600;
		width: 100%;
		text-align: center;
	}
	h3.title-comment {
		font-size: 12px;
	}
	.question-osym {
		color: #f90500;
		font-weight: 600;
		width: 100%;
		text-align: center;
		padding: 5px;
		font-size: 9px;
	}
	.pdf-notes-C {
		background-color: #f5c0b9;
		height: 100%;
		padding: 8px;
	}
	.pdf-notes-C p,
	.pdf-notes-C h1,
	.pdf-notes-C h2,
	.pdf-notes-C h3,
	.pdf-notes-C h4,
	.pdf-notes-C h5,
	.pdf-notes-C h6 {
		word-break: break-word;
		hyphens: manual;
		word-wrap: break-word;
	}
	.outer-data-list {
		border: none;
	}
	.outer-data-list > .r-data-list-list-C > .r-data-list-line-C {
		border: none !important;
		margin-bottom: 16px;
		background-color: transparent !important;
	}
	.outer-data-list > .r-data-list-list-C > .r-data-list-line-C:hover {
		background-color: transparent !important;
		border: 1px solid transparent;
	}
	.wrapper-data-list {
		margin: 10px 5px;
	}
	.teacher-list-data-list > .r-data-list-list-C > .r-data-list-line-C {
		background-color: transparent !important;
	}
	.teacher-list-data-list > .r-data-list-list-C > .r-data-list-line-C:hover {
		background-color: transparent !important;
		border: 1px solid transparent;
	}
	.ref-col-left {
		z-index: 9;
	}
	.pdf-content-desc {
		position: relative;
		z-index: 9;
	}
	.fixed-col-left {
		display: flex;
		position: fixed;
		height: calc(100% - 44px);
		top: 32px;
		overflow-y: hidden;
		width: calc(((1240px / 12) * 5) - 8px);
		z-index: 9;
		padding-right: 5px;
	}
	.fixed-references {
		display: flex;
		position: fixed;
		height: calc(100% - 104px);
		top: 104px;
		overflow-y: auto;
		width: calc(((1240px / 12) * 5) - 16px);
		z-index: 9;
		padding-right: 5px;
	}
	.fixed-col-right {
		margin-left: 41.6666667%;
	}
	.question-data img,
	.case-data img {
		max-height: 120px;
		width: auto;
	}
	.pdf-view-content-C,
	.pdf-view-images-C {
		position: relative;
		z-index: 9;
		align-content: flex-start;
	}
	.pdf-view-image img {
		max-width: 100%;
		cursor: pointer;
	}
	.pdf-footer,
	.pdf-footer-temp,
	.pdf-footer-save {
		background-color: #f90500;
		color: #fff;
		padding: 3px;
		font-weight: 600;
		flex: 0 auto;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 9;
	}
	.pdf-right-color {
		background-color: #f5c0b9;
		position: absolute;
		z-index: 0;
		height: 100%;
		top: 0;
		right: 0;
	}
	.pdf-img-line,
	.pdf-img-line-temp .pdf-img-line-save {
		flex: 0 0 auto;
	}
	/* fact:2,2857 */
	:root {
		--pdf-factor: 1600/700;
	}
	.outer-page-W-save {
		opacity: 0;
		position: fixed;
		left: 100%;
		top: 0;
		z-index: 0;
	}

	.pdf-screen-W-save {
		width: 664px;
		height: 991px;
		padding: 24px 0;
		display: flex;
		flex-direction: column;
		background-color: #fff;
	}
</style>
